import React from 'react';
import './Sponsors.css';

const Sponsors = () => {
  return (
    <section className="sponsors py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-10">Our Sponsors</h2>
        <div className="sponsor-logos grid grid-cols-2 md:grid-cols-4 gap-8">
          {/* Add your sponsor logos here */}
          <img src="path/to/logo1.png" alt="Sponsor 1" className="max-w-full h-auto mx-auto" />
          <img src="path/to/logo2.png" alt="Sponsor 2" className="max-w-full h-auto mx-auto" />
          {/* ... */}
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
