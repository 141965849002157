import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer py-6">
      <div className="footer-content container mx-auto flex justify-between items-center">
        <div className="footer-links flex space-x-6">
        </div>
        <p>&copy; 2024 Bleu.js</p>
      </div>
    </footer>
  );
};

export default Footer;
