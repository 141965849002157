import React, { useState } from 'react';
import { FaGithub, FaBook, FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import logo from '../assets/logo.svg';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header fixed w-full top-0 z-50">
      <nav className="navbar container mx-auto flex justify-between items-center p-4">
        <div className="logo-container flex items-center">
          <img src={logo} alt="Bleu.js Logo" className="logo h-12 w-auto mr-3" />
        </div>
        <div className="menu-icon md:hidden" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links flex space-x-6 ${isOpen ? 'nav-active' : ''} md:flex md:space-x-6`}>
          <li><a href="#features" className="hover:text-secondary transition-colors duration-300">Features</a></li>
          <li><a href="#installation" className="hover:text-secondary transition-colors duration-300">Installation</a></li>
          <li><a href="#usage" className="hover:text-secondary transition-colors duration-300">Usage</a></li>
          <li><a href="https://github.com/HelloblueAI/Bleu.js" target="_blank" className="hover:text-secondary transition-colors duration-300"><FaGithub /></a></li>
          <li><a href="#" className="hover:text-secondary transition-colors duration-300"><FaBook /></a></li>
        </ul>
      </nav>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="hero text-center py-20"
      >
        <h1 className="text-5xl font-bold mb-4">The Progressive JavaScript Framework</h1>
        <p className="text-xl mb-8">An innovative, efficient, and adaptable framework to tackle modern development challenges.</p>
        <button className="bg-primary hover:bg-secondary text-background font-bold py-2 px-4 rounded transition-colors duration-300">Get Started</button>
      </motion.div>
    </header>
  );
};

export default Header;
