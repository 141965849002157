import React from 'react';
import Header from './components/Header';
import Features from './components/Features';
import Sponsors from './components/Sponsors';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App bg-gray-900 text-white min-h-screen">
      <Header />
      <Features />
      <Sponsors />
      <Footer />
    </div>
  );
}

export default App;
