import React from 'react';
import { FaRocket, FaBolt, FaPuzzlePiece } from 'react-icons/fa';
import './Features.css';

const Features = () => {
  return (
    <section className="features py-20">
      <div className="container mx-auto text-center">
        <div className="feature mb-12">
          <FaRocket className="text-6xl text-blue-400 mx-auto mb-4" />
          <h2 className="text-3xl font-bold">Approachable</h2>
          <p className="text-lg">Quickly learn and integrate with the framework.</p>
        </div>
        <div className="feature mb-12">
          <FaBolt className="text-6xl text-blue-400 mx-auto mb-4" />
          <h2 className="text-3xl font-bold">Performant</h2>
          <p className="text-lg">High performance with minimal effort.</p>
        </div>
        <div className="feature mb-12">
          <FaPuzzlePiece className="text-6xl text-blue-400 mx-auto mb-4" />
          <h2 className="text-3xl font-bold">Versatile</h2>
          <p className="text-lg">Suitable for a variety of applications.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;
